<template>
  <div>
    <h1 class="text-h5 text-md-h4 mb-3" :class="fontWeightLightMd">
      {{ $t('site.preTitle') }}
    </h1>
    <v-expansion-panels v-model="openedPanel" flat>
      <v-expansion-panel>
        <v-expansion-panel-header :hide-actions="true"
          ><template v-slot:default="{}">
            <div class="d-flex justify-center justify-md-start">
              <v-chip-group v-model="selection" mandatory class="mx-auto">
                <v-chip
                  medium
                  color="identity white--text"
                  :outlined="selection === 'provider'"
                  input-value="employee"
                  class="px-4 py-10 text-h6 text-uppercase"
                  filter
                  filter-icon="mdi-check-circle"
                  value="employee"
                  @click="selectPersonal"
                  >Personal
                </v-chip>
                <v-chip
                  medium
                  color="blue white--text"
                  :outlined="selection === 'employee'"
                  input-value="provider"
                  class="px-4 py-10 text-h6 text-uppercase"
                  filter
                  filter-icon="mdi-check-circle"
                  value="provider"
                  @click="selectProvider"
                  >Proveedor
                </v-chip>
              </v-chip-group>
            </div></template
          ></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-card
            elevation="2"
            class="mx-auto mt-3"
            rounded="lg"
            v-if="isReadyForInvitation && $vuetify.breakpoint.smAndUp"
          >
            <v-card-title>
              <h1
                class="text-h6 text-lg-h5 text--secondary font-weight-ligth"
                :class="fontWeightLightMd"
              >
                Se enviará la invitación a:
              </h1>
            </v-card-title>
            <v-card-text>
              <v-sheet height="16" class="mb-4 ml-1">
                <h5 class="text-caption text--secondary">
                  <v-icon dense class="mr-1" v-if="party.alias"
                    >mdi-account</v-icon
                  >{{ party.alias }}
                  <v-icon dense class="mr-1 ml-3" v-if="party.email"
                    >mdi-email-send-outline</v-icon
                  >{{ formattedEmail }}
                  <v-icon dense class="mr-1 ml-3" v-if="party.phone"
                    >mdi-cellphone-message</v-icon
                  >{{ formattedPhone }}
                </h5>
              </v-sheet>
            </v-card-text>
          </v-card>
          <v-form ref="form" v-model="valid">
            <v-container class="pa-0">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="party.alias"
                    :label="'Alias'"
                    type="text"
                    prepend-icon="mdi-account"
                    required
                    :rules="rules.alias"
                  />
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    :label="'Correo del proveedor'"
                    v-model="party.email"
                    type="email"
                    prepend-icon="mdi-email-send-outline"
                    :rules="rules.optionalEmail"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4">
                  <v-text-field
                    :label="'Código internacional'"
                    v-model="party.code"
                    type="tel"
                    pattern="[\+]\d{3}[ ]?"
                    autocomplete="off"
                    maxlength="4"
                    prepend-icon="mdi-flag-variant-outline"
                    required
                    value="+52"
                    :rules="rules.optionalIntlCode"
                    @click="defaultCode($event)"
                    @keyup="defaultCode($event)"
                  />
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    :label="'Móvil del proveedor'"
                    v-model="party.phone"
                    type="tel"
                    pattern="\d{10}"
                    autocomplete="off"
                    maxlength="10"
                    prepend-icon="mdi-cellphone-message"
                    :rules="rules.optionalPhone"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import rules from '@/micro/app/rules.js'
import { defaultValue } from '@/micro/field'
export default {
  data: () => ({
    openedPanel: null,
    rules
  }),
  computed: {
    ...mapGetters('requests', ['party', 'groupName', 'isValidSection']),
    valid: {
      get: function() {
        return this.isValidSection('party')
      },
      set: function(value) {
        let v = value
        if (
          (v && this.party.code && this.party.phone && !!this.party.email) ||
          (v && this.party.email && !!this.party.phone)
        ) {
          v = true
        } else if (v && !this.party.email && !this.party.phone) {
          v = false
        }
        this.updateValidSection({
          section: 'party',
          value: this.groupName === 'employee' ? true : v
        })
      }
    },
    selection: {
      set(name) {
        // let validTerms = false
        if (name === 'employee') {
          this.selectGroup('employee')
          this.valid = true
          // validTerms = false
        } else if (name === 'provider') {
          this.selectGroup('provider')
          this.valid = false
          // validTerms = true
        }
        // this.updateValidSection({
        //   section: 'terms',
        //   value: validTerms
        // })
      },
      get() {
        return this.groupName
      }
    },
    formattedEmail() {
      return this.party.email ? `${this.party.email}` : ''
    },
    formattedPhone() {
      const mobile = this.party.phone
        ? `${this.party.code} ${this.party.phone}`
        : ''
      return mobile
    },
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    },
    isReadyForInvitation() {
      return (this.party.email || this.party.phone) && this.party.alias
    }
  },
  methods: {
    ...mapActions('requests', [
      'saveParty',
      'selectGroup',
      'reinitParty',
      'updateValidSection'
    ]),
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
    openPanel(index) {
      this.openedPanel = index
    },
    selectPersonal() {
      this.selection = 'employee'
      this.openPanel(0)
    },
    selectProvider() {
      this.reset()
      this.selection = 'provider'
      // It is a valid section 'cause it doesn't require terms
      this.updateValidSection({
        section: 'terms',
        value: true
      })
      this.openPanel(1)
    },
    defaultCode(evt) {
      this.party.code = defaultValue(evt, '+52')
    }
  },
  created() {
    this.selection = 'employee'
  }
}
</script>

<style>
.v-expansion-panel-header {
  padding: 0;
}
</style>
