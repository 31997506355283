var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({attrs:{"color":_vm.checkColor},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-decoration-underline black--text"},[_vm._v("Acepto las políticas de ingreso")])]},proxy:true}],null,true),model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"identity","dark":""}},[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-black"},[_vm._v("Compromiso")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.completedFormIcon))])],1)],1),_c('v-card-text',[_c('h5',{staticClass:"mt-5 mb-n4 pb-0 text-h6 text-uppercase"},[_vm._v(" Hago constar ")]),_c('h6',{staticClass:"mt-5 mt-0 pt-0 mb-3 text-body-1 grey-text text-darken-4"},[_vm._v(" Que "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("NO HE PRESENTADO")]),_vm._v(" alguno de los siguientes síntomas: ")]),_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"active-class":"pink--text","multiple":""},model:{value:(_vm.symptomSelected),callback:function ($$v) {_vm.symptomSelected=$$v},expression:"symptomSelected"}},[_vm._l((_vm.symptomItems),function(item,index){return [_c('v-list-item',{key:item.title,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(item.headline)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.subtitle)}})],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(item.action)}}),(!active)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-checkbox-blank-outline ")]):_c('v-icon',{attrs:{"color":"yellow darken-3"}},[_vm._v(" mdi-checkbox-intermediate ")])],1)]}}],null,true)}),(index < _vm.symptomItems.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1),_c('h5',{staticClass:"mt-5 mb-n4 pb-0 text-h6 text-uppercase"},[_vm._v(" Declaro ")]),_c('h6',{staticClass:"mt-5 mt-0 pt-0 mb-3 text-body-1 grey-text text-darken-4"},[_vm._v(" Que he cumplido con el compromiso de: ")]),_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"active-class":"pink--text","multiple":""},model:{value:(_vm.agreementSelected),callback:function ($$v) {_vm.agreementSelected=$$v},expression:"agreementSelected"}},[_vm._l((_vm.agreementItems),function(item,index){return [_c('v-list-item',{key:item.title,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(item.headline)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.subtitle)}})],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(item.action)}}),(!active)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]):_c('v-icon',{attrs:{"color":"green lighten-1"}},[_vm._v(" mdi-checkbox-marked-circle ")])],1)]}}],null,true)}),(index < _vm.agreementItems.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1),_c('h5',{staticClass:"mt-5 mb-n4 pb-0 text-h6 text-uppercase"},[_vm._v(" Y confirmo de enterado ")]),_c('h6',{staticClass:"mt-5 mt-0 pt-0 mb-3 text-body-1 grey-text text-darken-4"},[_vm._v(" Que si llegara a estar en contacto con una persona que ha salido positivo a la prueba de COVID-19: ")]),_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"active-class":"pink--text","multiple":""},model:{value:(_vm.vaccineSelected),callback:function ($$v) {_vm.vaccineSelected=$$v},expression:"vaccineSelected"}},[_vm._l((_vm.vaccineItems),function(item,index){return [_c('v-list-item',{key:item.title,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(item.headline)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.subtitle)}})],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(item.action)}}),(!active)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-alert-circle-check-outline ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-alert-circle-check ")])],1)]}}],null,true)}),(index < _vm.vaccineItems.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1)],1),_c('v-divider'),_c('v-card-subtitle',[_c('p',{staticClass:"mt-5 my-0 pt-0 px-5 text-overline grey-text text-darken-4"},[_vm._v(" Por favor selecciona cada enunciado para confirmar tu compromiso y puedas continuar con tu solicitud. ")])]),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-black",attrs:{"disabled":!_vm.hasFormCompleted,"color":"primary"},on:{"click":_vm.check}},[_vm._v(" Acepto el compromiso ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }