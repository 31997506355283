import { ddFormat } from '@/micro/time/timeTools'

export function suggestedTime(pOffestMin) {
  // @todo Replace by x % 15 === 0
  const offsetMin = pOffestMin || 15
  const now = new Date()
  let hh = now.getHours()
  let mm = now.getMinutes()
  if (mm + offsetMin > 59) {
    hh = hh + 1
    mm = offsetMin
  } else {
    mm = mm + offsetMin
  }
  const q = mm / 15
  let mmq = 0
  if (q < 1) mmq = 15
  else if (q > 1 && q < 2) mmq = 30
  else if (q > 2 && q < 3) mmq = 45
  const nextHour = hh + 1 > 23 ? 0 : hh + 1
  const hhq = q > 3 ? nextHour : hh

  return `${ddFormat(hhq)}:${ddFormat(mmq)}`
}
