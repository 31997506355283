<template>
  <v-stepper
    v-model="currentStep"
    flat
    v-touch="{
      left: () => next(),
      right: () => previous()
    }"
  >
    <v-stepper-header v-if="$vuetify.breakpoint.lgAndUp && false">
      <template v-for="(step, stepIndex) in steps">
        <v-stepper-step
          :complete="currentStep > stepIndex + 1"
          :key="`${stepIndex + 1}-step`"
          :step="stepIndex + 1"
          :rules="step.rules"
          editable
          color="identity white--text"
          >{{ step.header }} <small>{{ step.caption }}</small></v-stepper-step
        >
        <v-divider
          :key="`${stepIndex + 1}-divider`"
          v-if="$vuetify.breakpoint.lgAndUp && stepIndex < steps.length - 1"
        />
      </template>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        v-for="(step, stepIndex) in steps"
        :key="`${stepIndex + 1}-content`"
        :step="stepIndex + 1"
        :class="reducedContentPadding"
      >
        <v-card class="reset-card-style">
          <v-card-text>
            <slot :name="`step${stepIndex + 1}`"></slot>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn class="pr-4" @click="previous()" v-if="stepIndex > 0" rounded
              ><v-icon>mdi-menu-left</v-icon>{{ $t('general.back') }}</v-btn
            >
            <v-btn
              class="pl-4"
              @click="next()"
              v-if="stepIndex < steps.length - 1"
              rounded
              color="primary white--text"
              >{{ $t('general.continue')
              }}<v-icon>mdi-menu-right</v-icon></v-btn
            >
            <!-- Send -->
            <v-btn
              class="px-5"
              v-if="isValidRequest && stepIndex === steps.length - 1"
              elevation="6"
              type="submit"
              color="primary"
              rounded
              :loading="loading"
              :disabled="loading"
              @click="submit"
              >{{ $t('general.send') }}
              <v-icon class="ml-1">mdi-send</v-icon></v-btn
            >
            <!-- <v-card
              v-if="isValidRequest && stepIndex === steps.length - 1"
              class="d-flex justify-center pa-2 ml-3 rounded"
            > 
            </v-card>
            -->
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { truncate } from '@/micro/text'
export default {
  data: () => ({
    loading: false
  }),
  props: {
    steps: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('requests', [
      'draft',
      'groupName',
      'party',
      'isValidRequest',
      'currentStep'
    ]),
    reducedContentPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'v-stepper__content_xs'
      }
      return ''
    }
  },
  watch: {
    party: {
      handler: function(attrs) {
        this.steps[0].header = truncate(attrs.alias, 12) || 'Proveedor'
      },
      deep: true
    },
    groupName: {
      handler: function(newGroup) {
        this.steps[0].header =
          newGroup === 'employee' ? 'Personal' : 'Proveedor'
      },
      deep: true
    },
    draft: {
      handler: function(input) {
        this.steps[1].header = input.site || this.srcSteps[1].header
        this.steps[2].header = input.date || this.srcSteps[2].header
        this.steps[3].header =
          input.fromTime && input.toTime
            ? `de ${input.fromTime} a ${input.toTime}`
            : this.srcSteps[3].header
        this.steps[4].header = truncate(
          input.motivation || this.srcSteps[4].header,
          12
        )
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('requests', [
      'sendRequestToOrg',
      'incStep',
      'decStep',
      'resetStep',
      'showSendingAck',
      'newRequestSent',
      'newRequestSending'
    ]),
    next() {
      if (this.currentStep < this.steps.length) this.incStep()
    },
    previous() {
      if (this.currentStep > 1) this.decStep()
    },
    async submit() {
      this.loading = true
      try {
        this.newRequestSending()
        await this.sendRequestToOrg()
        this.newRequestSent()
        this.showSendingAck('ACCESS_REQUEST_SENT')
      } catch (error) {
        console.log('conn error', error)
      }
      this.loading = false
      this.resetStep()
      this.$router.push({ name: 'Requests' })
    }
  },
  created() {
    this.srcSteps = { ...this.steps }
  }
}
</script>

<style lang="scss">
.v-stepper__content_xs {
  padding: 4px 4px 2px 4px;
}
.reset-card-style {
  box-shadow: 0 0 white !important;
}
.v-stepper__label {
  width: 120px;
}
// .v-sheet.v-card:not(.v-sheet--outlined) {
//   box-shadow: none;
// }

.v-stepper {
  box-shadow: none;
}
</style>
