<template>
  <v-container fill-height fluid class="ma-0 pa-0">
    <v-row align="center" justify="center" no-gutters>
      <request-wizard :steps="steps">
        <template v-slot:step1>
          <!-- Party -->
          <provider-account />
        </template>

        <template v-slot:step2>
          <!-- Sites -->
          <v-row class="ml-1 mb-2">
            <div class="mt-md-1" @click="updateStep(1)">
              <v-icon :color="partyColor" class="mr-1">
                {{ partyIcon }}
              </v-icon>
              <span :class="partyText('party')">{{ partyName }}</span>
            </div>
          </v-row>
          <sites-cards />
        </template>

        <template v-slot:step3>
          <!-- Time:Date -->
          <v-row class="mb-2">
            <div class="mt-md-1 mr-3" @click="updateStep(1)">
              <v-icon :color="partyColor" class="mr-1">
                {{ partyIcon }}
              </v-icon>
              <span :class="partyText('party')">{{ partyName }}</span>
            </div>

            <div class="mt-md-1" @click="updateStep(2)">
              <v-icon color="identity accent-4" class="mr-1">
                mdi-domain
              </v-icon>
              <span :class="partyText('site')">{{ siteName }}</span>
            </div>
          </v-row>
          <v-row class="d-flex justify-center">
            <calendar />
          </v-row>
        </template>

        <template v-slot:step4>
          <!-- Time:Period -->
          <v-row class="ml-1 mb-2">
            <div class="mt-md-1 mr-3" @click="updateStep(1)">
              <v-icon :color="partyColor" class="mr-1">
                {{ partyIcon }}
              </v-icon>
              <span :class="partyText('party')">{{ partyName }}</span>
            </div>

            <div class="mt-md-1 mr-3" @click="updateStep(2)">
              <v-icon color="identity accent-4" class="mr-1">
                mdi-domain
              </v-icon>
              <span :class="partyText('site')">{{ siteName }}</span>
            </div>

            <div class="mt-md-1" @click="updateStep(3)">
              <v-icon color="identity accent-4" class="mr-1">
                mdi-calendar
              </v-icon>
              <span :class="partyText('date')">{{ dateText }}</span>
            </div>
          </v-row>

          <time-period />
        </template>

        <template v-slot:step5>
          <!-- Motivation -->
          <v-row class="ml-1 mb-2">
            <div class="mt-md-1 mr-3" @click="updateStep(1)">
              <v-icon :color="partyColor" class="mr-1">
                {{ partyIcon }}
              </v-icon>
              <span :class="partyText('party')">{{ partyName }}</span>
            </div>
            <div class="mt-md-1 mr-3" @click="updateStep(2)">
              <v-icon color="identity accent-4" class="mr-1">
                mdi-domain
              </v-icon>
              <span :class="partyText('site')">{{ siteName }}</span>
            </div>
            <div class="mt-md-1">
              <v-icon color="identity accent-4" class="mr-1">
                mdi-calendar-clock
              </v-icon>
              <span :class="partyText('date')" @click="updateStep(3)">{{
                dateText
              }}</span>
              <span :class="partyText('time')" @click="updateStep(4)">{{
                timeText
              }}</span>
            </div>
          </v-row>
          <h1 class="text-h5 text-md-h4 mt-6 mb-3" :class="fontWeightLightMd">
            {{ $t('request.motivation') }}
          </h1>
          <v-form v-model="valid">
            <v-textarea
              v-model="motivation"
              counter="150"
              color="identity"
              clearable
              :prepend-icon="iconComment"
              label=""
              :rules="[rules.length(150), rules.required]"
              rows="4"
              class="text-h5"
            ></v-textarea>
          </v-form>

          <!-- Terms -->
          <h5 class="mt-12 text-h5">
            Por favor, contesta la siguiente entrevista
          </h5>
          <covid-terms v-if="groupName === 'employee'" />
        </template>
      </request-wizard>
    </v-row>
  </v-container>
</template>

<script>
import RequestWizard from '@/views/RequestWizard'
import ProviderAccount from '@/components/people/ProviderAccount'
import SitesCards from '@/components/sites/SitesCards'
import Calendar from '@/components/time/Calendar'
import TimePeriod from '@/components/time/TimePeriod'
import CovidTerms from '@/components/CovidTerms'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { compareAsc } from 'date-fns'
import { ddFormat } from '@/micro/time/timeTools'
export default {
  name: 'Request',
  components: {
    RequestWizard,
    ProviderAccount,
    SitesCards,
    Calendar,
    TimePeriod,
    CovidTerms
  },
  data: () => ({
    rules: {
      length: len => v => (v || '').length <= len || `Máximo ${len} caracteres`,
      required: v => !!v || 'En necesario que escribas el motivo de la visita'
    },
    steps: [
      {
        name: 'party',
        header: 'Personal',
        caption: '',
        rules: [() => true]
      },
      {
        name: 'site',
        header: 'Sitio deseado',
        caption: '',
        rules: [() => true]
      },
      {
        name: 'date',
        header: 'Fecha deseada',
        caption: '',
        rules: [() => true]
      },
      {
        name: 'time',
        header: 'Entrada y salida',
        caption: '',
        rules: [() => true]
      },
      {
        name: 'motivation',
        header: 'Motivo de la visita',
        caption: '',
        rules: [() => true]
      }
    ]
  }),
  computed: {
    ...mapGetters('requests', ['draft', 'groupName', 'isValidSection']),
    ...mapGetters('sites', ['siteById']),
    filled() {
      return this.draft.motivation ? this.draft.motivation.length > 0 : false
    },
    iconComment() {
      return this.filled ? 'mdi-comment-check' : 'mdi-comment'
    },
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    },
    partyName() {
      return this.groupName === 'employee'
        ? 'Personal'
        : this.isValidSection('party')
        ? this.draft.party.attrs.alias
        : '¡Proveedor fantasma!'
    },
    siteName() {
      return this.draft.site
        ? this.siteById(this.draft.site).name
        : '¡Sitio inválido!'
    },
    dateText() {
      if (this.draft.dates.length > 1) {
        const datesStrs = this.draft.dates
        const dates = datesStrs.map(d => new Date(`${d}T00:00`))
        const sorted = dates.sort(compareAsc)
        const displayStr = sorted
          .map(date => date.getDate())
          .reduce((r, v) => {
            const fv = ddFormat(v)
            if (r.length < 10) {
              return `${r} ${fv}`
            } else {
              const str = `${r} ${fv} ...`.substr(0, 18)
              const lastDay = v + ''
              return str.substr(-2) === lastDay ? str : `${str} ${lastDay}`
            }
          }, '')
        return displayStr
      } else {
        return this.draft.dates.length > 0
          ? moment(this.draft.dates[0]).format('ddd D MMM YYYY')
          : '¿Y las Fechas?'
      }
    },
    timeText() {
      return !this.draft.fromTime && !this.draft.toTime
        ? ', ¿Tiempos difíciles?'
        : this.draft.duration
        ? `, de ${this.draft.fromTime} a ${this.draft.toTime}`
        : '¡Duración inválida!'
    },
    partyIcon() {
      return this.groupName === 'employee'
        ? 'mdi-account-circle'
        : this.isValidSection('party')
        ? 'mdi-account-hard-hat'
        : 'mdi-account-off-outline'
    },
    partyColor() {
      return this.groupName === 'employee'
        ? 'identity'
        : this.isValidSection('party')
        ? 'blue'
        : 'red'
    },
    motivationColor() {
      return 'text-ok'
    },
    motivation: {
      set: function(val) {
        this.saveDraft({ key: 'motivation', value: val })
      },
      get: function() {
        return this.draft.motivation
      }
    },
    valid: {
      get: function() {
        return this.isValidSection('motivation')
      },
      set: function(value) {
        this.updateValidSection({ section: 'motivation', value })
      }
    }
  },
  methods: {
    ...mapActions('requests', [
      'saveDraft',
      'updateValidSection',
      'updateStep'
    ]),
    partyText(section) {
      return this.isValidSection(section) ? 'text-ok' : 'text-fail'
    }
  }
}
</script>

<style scoped>
.text-ok {
  color: #2b3535;
  font-weight: 600;
}
.text-fail {
  color: #ff1744;
}
</style>
