const moment = require('moment')
require('moment-precise-range-plugin')
import { isDurationObject, hhmm, inMinutes } from '@/micro/time/timeTools'
import { format, intervalToDuration } from 'date-fns'

/**
 * Fn: elapsed = end - start =
 * 	   2019-07-13T10:35:00.328Z - 2019-07-13T10:31:00.328Z = {hh: 0, mm:4}
 *     1563720224098 - 'Sun Jul 21 2019 09:43:44 GMT- ...' = {hh: 0, mm:0}
 *
 * @todo Replace it by the following version 2
 * @private
 * @param {string|number} `start` Text|number representing a point in time
 * @param {string|number} `end` Text|number representing a point in time
 * @returns {hh, mm} The time without Date context
 */
const elapsedOfTimes = (start, end) => {
  const elapsed = moment.preciseDiff(moment(end), moment(start), true)
  return { hh: elapsed.hours, mm: elapsed.minutes }
}

const elapsedOfTimes2 = (start, end) => {
  const duration = intervalToDuration({ start, end })
  return { hh: duration.hours, mm: duration.minutes }
}

/**
 * Fn: duration = to - from =
 * 		{hh: 0, mm:35 } - {hh: 0, mm:31 } = {hh: 0, mm:4}
 *
 * @todo Replace it by the following version 2
 * @private
 * @param {object} `from` {hh, mm}
 * @param {object} `to` {hh, mm}
 * @returns {object} The duration between two hours without date context
 */
const elapsedOfDurations = (iFrom, iTo) => {
  const from = isDurationObject(iFrom) ? iFrom : hhmm(iFrom)
  const to = isDurationObject(iTo) ? iTo : hhmm(iTo)
  const fromDuration = moment.duration({ hour: from.hh, minute: from.mm })
  const toDuration = moment.duration({ hour: to.hh, minute: to.mm })
  const duration = toDuration.clone().subtract(fromDuration)
  return { hh: duration.hours(), mm: duration.minutes() }
}

const elapsedOfDurations2 = (iFrom, iTo) => {
  const from = isDurationObject(iFrom) ? iFrom : hhmm(iFrom)
  const to = isDurationObject(iTo) ? iTo : hhmm(iTo)
  const anyDate = format(new Date(), 'YY-MM-DD')
  const start = new Date(`${anyDate}T${from.hh}:${from.mm}`)
  const end = new Date(`${anyDate}T${to.hh}:${to.mm}`)
  return elapsedOfTimes2(start, end)
}

/**
 * @public
 */
const elapsed = {
  /**
   * @param {string} start Datetime in string '2021-10-29 23:13'
   * @param {string} end Datetime in string
   */
  ofTimes: (start, end) => {
    return elapsedOfTimes(start, end)
  },
  ofTimes2: (start, end) => {
    return elapsedOfTimes2(start, end)
  },
  /**
   * @param {Object|String} from hh:mm
   * @param {Object|String} to hh:mm
   */
  ofDurations: (from, to) => {
    return elapsedOfDurations(from, to)
  },

  ofDurations2: (from, to) => {
    return elapsedOfDurations2(from, to)
  }
}

/**
 * Alorigthm: start(8:00), end(8:15), expected(0:15)
 * 	elapsed = end - start = 8:15 - 8:00
 * 	remaining = expected - ellapsed = 0:30 - 0:15
 *
 * @public
 * @param {Date|number} Start time period
 * @param {Date|number} End time period
 * @param {hh, mm} `expected` The expected time duration
 * @returns {object} {hh, mm} The remaining time in the timer
 */
const remaining = (start, end, { hh, mm }) => {
  const effectiveDuration = elapsedOfTimes(start, end)
  const residual = elapsedOfDurations(effectiveDuration, { hh, mm })
  return residual
}

/**
 *
 * @param {{hh, mm}} remaining
 * @returns bool
 */
const stillRemains = duration => inMinutes.ofObject(duration) > 0

/**
 * Import them as module or as inviduals for tree shaking
 */
export { elapsed, remaining, stillRemains }
