<template>
  <v-container fluid>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-checkbox v-model="checked" :color="checkColor" v-on="on">
          <template v-slot:label>
            <span class="text-decoration-underline black--text"
              >Acepto las políticas de ingreso</span
            >
          </template>
        </v-checkbox>
      </template>

      <v-card>
        <v-toolbar color="identity" dark>
          <v-toolbar-title class="text-h5 font-weight-black"
            >Compromiso</v-toolbar-title
          >

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon>{{ completedFormIcon }}</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <h5 class="mt-5 mb-n4 pb-0 text-h6 text-uppercase">
            Hago constar
          </h5>
          <h6 class="mt-5 mt-0 pt-0 mb-3 text-body-1 grey-text text-darken-4">
            Que
            <span class="font-weight-bold">NO HE PRESENTADO</span>
            alguno de los siguientes síntomas:
          </h6>

          <v-card class="mx-auto" max-width="500">
            <v-list two-line>
              <v-list-item-group
                v-model="symptomSelected"
                active-class="pink--text"
                multiple
              >
                <template v-for="(item, index) in symptomItems">
                  <v-list-item :key="item.title">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.title"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.headline"
                        ></v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-text="item.subtitle"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="item.action"
                        ></v-list-item-action-text>

                        <v-icon v-if="!active" color="grey lighten-1">
                          mdi-checkbox-blank-outline
                        </v-icon>

                        <v-icon v-else color="yellow darken-3">
                          mdi-checkbox-intermediate
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < symptomItems.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>

          <h5 class="mt-5 mb-n4 pb-0 text-h6 text-uppercase">
            Declaro
          </h5>
          <h6 class="mt-5 mt-0 pt-0 mb-3 text-body-1 grey-text text-darken-4">
            Que he cumplido con el compromiso de:
          </h6>

          <v-card class="mx-auto" max-width="500">
            <v-list two-line>
              <v-list-item-group
                v-model="agreementSelected"
                active-class="pink--text"
                multiple
              >
                <template v-for="(item, index) in agreementItems">
                  <v-list-item :key="item.title">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.title"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.headline"
                        ></v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-text="item.subtitle"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="item.action"
                        ></v-list-item-action-text>

                        <v-icon v-if="!active" color="grey lighten-1">
                          mdi-checkbox-marked-circle-outline
                        </v-icon>

                        <v-icon v-else color="green lighten-1">
                          mdi-checkbox-marked-circle
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < agreementItems.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>

          <h5 class="mt-5 mb-n4 pb-0 text-h6 text-uppercase">
            Y confirmo de enterado
          </h5>
          <h6 class="mt-5 mt-0 pt-0 mb-3 text-body-1 grey-text text-darken-4">
            Que si llegara a estar en contacto con una persona que ha salido
            positivo a la prueba de COVID-19:
          </h6>

          <v-card class="mx-auto" max-width="500">
            <v-list two-line>
              <v-list-item-group
                v-model="vaccineSelected"
                active-class="pink--text"
                multiple
              >
                <template v-for="(item, index) in vaccineItems">
                  <v-list-item :key="item.title">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.title"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.headline"
                        ></v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-text="item.subtitle"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="item.action"
                        ></v-list-item-action-text>

                        <v-icon v-if="!active" color="grey lighten-1">
                          mdi-alert-circle-check-outline
                        </v-icon>

                        <v-icon v-else color="primary">
                          mdi-alert-circle-check
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < vaccineItems.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-subtitle>
          <p class="mt-5 my-0 pt-0 px-5 text-overline grey-text text-darken-4">
            Por favor selecciona cada enunciado para confirmar tu compromiso y
            puedas continuar con tu solicitud.
          </p>
        </v-card-subtitle>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!hasFormCompleted"
            color="primary"
            class="font-weight-black"
            @click="check"
          >
            Acepto el compromiso
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      symptomSelected: [],
      agreementSelected: [],
      vaccineSelected: [],
      symptomItems: [
        {
          title: 'Malestar general y/o fatiga',
          headline: '',
          subtitle: `en los últimos 14 días`,
          action: 'NO'
        },
        {
          title: 'Fiebre',
          headline: 'Temperatura superior a 37’5ºC',
          subtitle: `en los últimos 7 días`,
          action: 'NO'
        },
        {
          title: 'Dolor de cabeza',
          headline: '',
          subtitle: `en los últimos 7 días`,
          action: 'NO'
        },
        {
          title: 'Escurrimiento nasal',
          headline: 'O nariz congestionada',
          subtitle: `en los últimos 7 días`,
          action: 'NO'
        },
        {
          title: 'Pérdida del olfato',
          headline: 'Parcial o completa',
          subtitle: `en los últimos 14 días`,
          action: 'NO'
        },
        {
          title: 'Garganta',
          headline: 'Dolor, picazón o ronquera',
          subtitle: `en los últimos 7 días`,
          action: 'NO'
        },
        {
          title: 'Tos',
          headline: 'Tos seca (sin flemas)',
          subtitle: `en los últimos 7 días`,
          action: 'NO'
        },
        {
          title: 'Pérdida del gusto',
          headline: 'O cambios en los sabores',
          subtitle: `en los últimos 14 días`,
          action: 'NO'
        },
        {
          title: 'Dificultad respiratoria',
          headline: '',
          subtitle: `en los últimos 14 días`,
          action: 'NO'
        }
      ],
      agreementItems: [
        {
          title: 'No haber viajado vía aérea',
          headline: '',
          subtitle: `en los últimos 7 días`,
          action: '7d'
        },
        {
          title: 'No haber tenido contacto',
          headline: 'con personas con COVID-19',
          subtitle: `en los últimos 7 días`,
          action: '7d'
        },
        {
          title: 'Tomar las medidas',
          headline: 'preventivas necesarias',
          subtitle: `todos los días`,
          action: 'cada día'
        }
      ],
      vaccineItems: [
        {
          title: 'Si estoy vacunado',
          headline: 'Me aislaré con cubrebocas',
          subtitle: `durante 5 días`,
          action: 'ENTERADO'
        },
        {
          title: 'Si no estoy vacunado',
          headline: 'Me aislaré con cubrebocas',
          subtitle: `durante 14 días`,
          action: 'ENTERADO'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('requests', ['draft']),
    checkColor: function() {
      return this.checked ? 'identity' : 'red accent-3'
    },
    completedFormIcon() {
      return this.hasFormCompleted
        ? 'mdi-checkbox-marked-circle'
        : 'mdi-checkbox-marked-circle-outline'
    },
    hasFormCompleted() {
      return (
        this.symptomSelected.length === 9 &&
        this.agreementSelected.length === 3 &&
        this.vaccineSelected.length === 2
      )
    },
    checked: {
      set: function(val) {
        this.saveDraft({ key: 'terms', value: val })
        this.updateValidSection({ section: 'terms', value: !!val })
      },
      get: function() {
        return this.draft.terms
      }
    }
  },
  methods: {
    ...mapActions('requests', ['saveDraft', 'updateValidSection']),
    check() {
      this.dialog = false
      this.checked = true
    }
  }
}
</script>

<style scoped>
li {
  line-height: 1.4rem;
}
</style>
