<template>
  <div class="px-4">
    <h1 class="text-h5 text-md-h4 mt-6 mb-4 mb-md-3" :class="fontWeightLightMd">
      Selecciona la entrada y salida
    </h1>

    <!-- Fields component -->
    <v-row>
      <v-col cols="12" md="6">
        <v-dialog
          ref="fromDialog"
          v-model="fromMenu"
          :return-value.sync="fromTime"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="fromTime | suffixHrs"
              :label="$t('time.from')"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            elevation="15"
            v-if="fromMenu"
            v-model="fromTime"
            format="24hr"
            :allowed-minutes="allowedStep"
            full-width
            color="identity"
            @click:hour="autoAdjustFrom"
          >
            <v-spacer></v-spacer>
            <v-btn
              class="px-4"
              dark
              rounded
              color="grey lighten-1"
              @click="fromMenu = false"
            >
              {{ $t('general.cancel') }}
            </v-btn>
            <v-btn
              dark
              rounded
              color="primary"
              class="px-4"
              @click="$refs.fromDialog.save(fromTime)"
            >
              {{ $t('general.accept') }}
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="6">
        <v-dialog
          ref="toDialog"
          v-model="toMenu"
          :return-value.sync="toTime"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="toTime | suffixHrs"
              :label="$t('time.to')"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="toMenu"
            v-model="toTime"
            format="24hr"
            :allowed-minutes="allowedStep"
            full-width
            color="identity"
            @click:hour="autoAdjustTo"
          >
            <v-spacer></v-spacer>
            <v-btn
              class="px-4"
              dark
              rounded
              color="grey lighten-1"
              @click="toMenu = false"
            >
              {{ $t('general.cancel') }}
            </v-btn>

            <v-btn
              dark
              rounded
              color="primary"
              class="px-4"
              @click="$refs.toDialog.save(toTime)"
            >
              {{ $t('general.accept') }}
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <!-- Duration component -->
    <v-sheet class="durationPosition mt-5">
      <v-row justify="center" no-gutters>
        <v-avatar color="" v-if="!validDuration" size="36">
          <span
            ><v-icon :color="durationColor">
              mdi-clock-alert-outline
            </v-icon></span
          >
        </v-avatar>
        <v-avatar v-if="validDuration" size="36">
          <span
            ><v-icon :color="checkColor">
              mdi-check
            </v-icon></span
          >
        </v-avatar>
      </v-row>
      <v-row no-gutters class="mb-16 mb-md-8">
        <v-col cols="2">
          <v-btn
            class="mr-1"
            icon
            color="red"
            @click="subtract(15, 'm')"
            x-large
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" class="text-center" align-self="center">
          <v-chip
            class="fix-width"
            :color="durationColor"
            text-color="white"
            chip
            outlined
            large
          >
            <v-chip
              :color="durationColor"
              text-color="white"
              class="fix-width text-h6"
            >
              Duración {{ formattedDuration }}
            </v-chip>
          </v-chip>
        </v-col>
        <v-col cols="2">
          <v-btn icon color="green" @click="add(15, 'm')" x-large>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
const moment = require('moment')
import { elapsed, stillRemains } from '@/micro/time/timer'
import {
  ddFormat,
  durationOfText,
  formatted,
  isDurationNegative,
  isDurationTxtNegative
} from '@/micro/time/timeTools'
import { mapGetters, mapActions } from 'vuex'
import {
  toLocalPlainDateTime,
  extractDate,
  extractDateTimeFromUtc
} from '@/micro/time/date'
import { isNowBeforeExtended } from '@/micro/time/dateCompare'
import { addDays, subDays, isEqual } from 'date-fns'
import { suggestedTime } from './timeControl'

export default {
  name: 'TimePeriod',
  data() {
    return {
      fromMenu: false,
      toMenu: false
    }
  },

  props: {
    duarationMarginTop: {
      type: String,
      required: false,
      default: 'mt-12'
    }
  },

  computed: {
    ...mapGetters('requests', ['draft']),
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    },
    fromTime: {
      set: function(val) {
        this.saveDraft({ key: 'fromTime', value: val })
      },
      get: function() {
        return this.draft.fromTime
      }
    },
    toTime: {
      set: function(val) {
        this.saveDraft({ key: 'toTime', value: val })
      },
      get: function() {
        return this.draft.toTime
      }
    },
    durationPosition() {
      return `mx-auto ${this.duarationMarginTop}`
    },
    durationColor() {
      return this.validDuration ? 'identity' : 'grey darken-1'
    },
    checkColor() {
      return this.validDuration ? 'green' : 'grey darken-1'
    },
    formattedDuration() {
      const d = this.duration
      return this.durationFormatted(
        moment.duration({ hour: d.hh, minute: d.mm })
      )
    },
    validDuration() {
      return stillRemains(this.duration) && !!this.fromTime
    },
    duration() {
      return elapsed.ofTimes(this.draft.fromDateTime, this.draft.toDateTime)
    },
    checkIcon() {
      return this.validDuration ? 'mdi-check' : ''
    }
  },

  filters: {
    suffixHrs: time => (time ? `${time} hrs` : '')
  },

  methods: {
    ...mapActions('requests', [
      'saveDraft',
      'updateValidSection',
      'setFromDateTime',
      'setToDateTime'
    ]),
    autoAdjustFrom() {
      // if (!this.fromTime) {
      //   this.fromTime = 0
      // }
    },
    autoAdjustTo(hours) {
      if (!this.toTime) {
        this.toTime = `${ddFormat(hours)}:00`
      }
    },
    allowedStep: m => m % 5 === 0,
    add(val, unit) {
      if (!this.toTime) this.toTime = this.fromTime
      const d = this.txtToDuration(this.toTime).add(val, unit)
      this.toTime = this.durationFormatted(d)
    },
    subtract(val, unit) {
      if (!this.toTime) this.toTime = this.fromTime
      const d = this.txtToDuration(this.toTime).subtract(val, unit)
      const toTime = this.durationFormatted(d)
      if (!isDurationTxtNegative(toTime)) {
        this.toTime = toTime
      }
      // else {
      //   this.toTime = '23:45'
      // }
    },
    txtToDuration(hhmm) {
      const d = durationOfText(hhmm)
      return moment.duration({ hour: d.hh, minute: d.mm })
    },
    durationFormatted(d) {
      return formatted(
        'hh:mm',
        { hh: d.hours(), mm: d.minutes() },
        { hhZero: '00' }
      )
    }
  },

  watch: {
    fromTime(newFrom) {
      if (this.draft.date) {
        const fromDate = extractDate(this.draft.fromDateTime)
        if (
          !this.newTo ||
          !stillRemains(
            elapsed.ofTimes(this.draft.fromDateTime, this.draft.toDateTime)
          )
        ) {
          // Init with the same dates
          this.setFromDateTime(toLocalPlainDateTime(fromDate, newFrom))
          this.setToDateTime(toLocalPlainDateTime(fromDate, newFrom))
          this.toTime = newFrom
        } else if (
          !isNowBeforeExtended(toLocalPlainDateTime(fromDate, newFrom), 15)
        ) {
          // Ensure the schedule time is after now + n minutes
          const toDate = extractDate(this.draft.toDateTime)
          const time = suggestedTime()
          this.setToDateTime(toLocalPlainDateTime(toDate, time))
          this.toTime = time
        } else {
          // Update the from date time
          this.setFromDateTime(toLocalPlainDateTime(fromDate, newFrom))
        }
      }
    },

    toTime(newTo) {
      if (this.draft.date) {
        const fromDate = extractDate(this.draft.fromDateTime)
        const toDate = extractDate(this.draft.toDateTime)
        let toDateTimePicker = toLocalPlainDateTime(toDate, newTo)
        const areDatesEqual = isEqual(new Date(fromDate), new Date(toDate))
        const isNewDurNeg = isDurationNegative(
          elapsed.ofDurations(this.fromTime, newTo)
        )

        const utcDate = new Date(toDateTimePicker)
        if (isNewDurNeg && areDatesEqual) {
          const adjusted = addDays(utcDate, 1)
          toDateTimePicker = extractDateTimeFromUtc(adjusted)
        } else if (!isNewDurNeg && !areDatesEqual) {
          const adjusted = subDays(utcDate, 1)
          toDateTimePicker = extractDateTimeFromUtc(adjusted)
        }

        if (
          !stillRemains(
            elapsed.ofTimes(this.draft.fromDateTime, toDateTimePicker)
          )
        ) {
          // If not any duration, then set fromTime = toTime
          this.setToDateTime(toDateTimePicker)
          this.fromTime = newTo
        } else {
          this.setToDateTime(toDateTimePicker)
        }
      }
    },

    duration(newTo) {
      this.saveDraft({ key: 'duration', value: newTo })
      this.updateValidSection({ section: 'time', value: this.validDuration })
    }
  },

  created() {
    // this.fromTime = this.draft.fromTime ? this.draft.fromTime : suggestedTime()
  }
}
</script>

<style lang="scss">
.v-time-picker-clock__ampm .v-picker__title__btn {
  font-weight: bold !important;
  padding: 6px;
  border-radius: 20px;
}
.v-time-picker-clock__ampm .v-picker__title__btn--active {
  background-color: $identity;
  color: white;
}
.fix-width {
  width: 196px;
}
// .v-input {
//   font-size: 18px;
// }
.v-input input {
  max-height: 36px;
  font-size: 1.6rem !important;
}
.theme--light.v-icon {
  color: rgba(0, 0, 0, 0.68);
}
.theme--light.v-time-picker-clock {
  background: repeating-radial-gradient(grey, transparent 70px);
}

.v-time-picker-clock__item {
  font-size: 20px;
  font-weight: 500;
}
.theme--light.v-time-picker-clock .v-time-picker-clock__item--disabled {
  color: rgba(0, 0, 0, 0.1);
}
</style>
