<template>
  <div class="px-4">
    <h1 class="text-h5 text-md-h4 mb-3" :class="fontWeightLightMd">
      Selecciona los días
    </h1>
    <v-date-picker
      class="rounded-lg"
      :year-icon="checkIcon"
      reactive
      :full-width="fullWidth"
      v-model="dates"
      :multiple="multiple"
      color="identity"
      header-color="identity"
      elevation="2"
      :show-current="isEmpty"
      locale="es-mx"
      :landscape="landscape"
      :min="minDate()"
      :max="maxDate()"
      :title-date-format="formattedDateTitle"
      @touchstart.native.stop
      @touchmove.native.stop
      @touchend.native.stop
    ></v-date-picker>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { toLocalPlainDateTime } from '@/micro/time/date'
import { suggestedTime } from './timeControl'

export default {
  name: 'Calendar',
  data: () => ({
    menu: false,
    landscape: false,
    multiple: true
  }),
  computed: {
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    },
    isEmpty() {
      return this.dates.length === 0
    },
    fullWidth() {
      return this.$vuetify.breakpoint.smAndUp ? false : true
    },
    checkIcon() {
      return this.isEmpty ? 'mdi-calendar-blank' : 'mdi-calendar-check'
    },
    ...mapGetters('requests', ['draft']),
    dates: {
      get: function() {
        return this.draft.dates
      },
      set: function(value) {
        if (this.draft.dates.length === 0) {
          const single = value[0]
          this.saveDraft({ key: 'date', value: single })
          let time = this.draft.fromTime || suggestedTime()
          if (value) {
            const dateTimePicker = toLocalPlainDateTime(value, time)
            this.setFromDateTime(dateTimePicker)
            this.setToDateTime(dateTimePicker)
            this.saveDraft({ key: 'fromTime', value: time })
          }
        }

        this.saveDraft({ key: 'dates', value })

        this.updateValidSection({ section: 'date', value: !!value })
      }
    }
  },
  methods: {
    ...mapActions('requests', [
      'saveDraft',
      'updateValidSection',
      'setFromDateTime',
      'setToDateTime'
    ]),
    minDate() {
      return moment().format('YYYY-MM-DD')
    },
    maxDate() {
      return moment()
        .add(30, 'days')
        .format('YYYY-MM-DD')
    },
    formattedDateTitle() {
      if (this.dates.length === 0) {
        return 'Sin día seleccionado'
      } else if (this.dates.length === 1) {
        return !this.isEmpty ? moment(this.dates[0]).format('dddd, D MMMM') : ''
      } else {
        const dates = this.dates.reduce((prev, date) => {
          if (prev.length < 20) {
            return `${prev} ${date.substr(8, 2)}`
          } else {
            const str = `${prev} ${date.substr(8, 2)} ...`.substr(0, 29)
            const lastDay = date.substr(8, 2)
            return str.substr(22, 2) === lastDay ? str : `${str} ${lastDay}`
          }
        }, '')
        return dates
      }
    }
  },
  created() {
    moment.locale('es-mx')
  }
}
</script>

<style lang="scss">
.v-date-picker-header__value button {
  font-weight: 500;
  font-size: 1.25rem;
}
.v-date-picker-table th {
  font-size: 1.25rem;
}
.theme--light.v-date-picker-table th,
.theme--light.v-date-picker-table .v-date-picker-table--date__week {
  color: rgba(0, 0, 0, 0.8);
}

.v-date-picker-table--date th {
  font-weight: 500;
}

.v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
  // Header input
  font-weight: 400 !important;
  font-size: 1.5rem;
}
.v-date-picker-table .v-btn {
  // day btn
  font-size: 20px;
}
.v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
  text-transform: uppercase;
}

.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  height: 30px;
  font-size: 30px;
  width: 30px;
  font-weight: 700;
}
.v-btn--icon.v-size--default {
  width: 48px;
  height: 48px;
}

.theme--light.v-btn.v-btn--icon {
  color: black;
}

.v-date-picker-table {
  height: auto !important;
}

.v-date-picker-table--date {
  padding-bottom: 10px;
}

.v-date-picker-table--date .v-btn {
  height: 38px;
  width: 40px;
}
.theme--light.v-btn.v-btn--disabled {
  color: rgba(0, 0, 0, 0.29) !important;
  font-weight: bolder;
}
</style>
